@charset "UTF-8";
html {
  scroll-padding-top: 90px;
}

/*===========================
reset style
===========================*/
/* autoprefixer grid: autoplace */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  font-family: "Noto Sans JP", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "游ゴシック体", "Yu Gothic", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: #555251;
  background: #EDEDED;
  font-size: 16px;
  line-height: 1.75;
  font-feature-settings: "palt";
  letter-spacing: 0.1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #555251;
}

p {
  font-size: 16px;
  color: #555251;
}

ul,
ol,
li {
  list-style: none;
  color: #555251;
}

a {
  text-decoration: none;
  color: #333333;
}
a:visited {
  text-decoration: none;
  color: #333333;
}
a:hover {
  text-decoration: none;
}

address {
  font-style: normal;
  color: #555251;
}

img {
  border: none;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

dl,
dt,
dd {
  margin: 0;
  padding: 0;
  color: #555251;
}

main {
  display: block;
}

picture {
  display: block;
}

table,
th,
td {
  color: #555251;
}

header {
  display: block;
}

footer {
  display: block;
}

aside {
  display: block;
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

small {
  display: block;
}

data {
  display: block;
}

time {
  display: block;
}

a[href^="tel:"] {
  pointer-events: none;
  cursor: default;
}
@media screen and (max-width: 960px) {
  a[href^="tel:"] {
    pointer-events: auto;
    cursor: pointer;
  }
}

/*==========================
l-header
==========================*/
.l-header {
  width: 100%;
  padding: 30px 0;
  background: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  transform: translateY(0);
  transition: 0.3s;
}
@media screen and (max-width: 960px) {
  .l-header {
    height: 70px;
    background: #ffffff;
  }
}
.l-header__flex {
  display: flex;
  justify-content: space-between;
  align-items: end;
  transition: 0.3s;
}
.l-header__split:nth-of-type(1) {
  width: 30%;
}
@media screen and (max-width: 960px) {
  .l-header__split:nth-of-type(1) {
    width: 62%;
  }
}
.l-header__split:nth-of-type(2) {
  width: 70%;
}
@media screen and (max-width: 960px) {
  .l-header__split:nth-of-type(2) {
    width: calc(100% - 62%);
  }
}
.l-header__split:nth-of-type(2) p {
  text-align: right;
  font-size: 12px;
}
@media screen and (max-width: 1200px) {
  .l-header__split:nth-of-type(2) p {
    font-size: 1vw;
  }
}
.l-header__logo {
  width: 230px;
}
@media screen and (max-width: 960px) {
  .l-header__logo {
    width: 100%;
  }
}
.l-header__logo a {
  display: block;
}
.l-header__logo img {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .l-header__link {
    display: none;
  }
}
.l-header__gnav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
  margin-top: 50px;
}
.l-header__gnav li:not([class]) {
  width: calc((100% - 10% * 5) / 6);
}
.l-header__gnav a {
  display: block;
  color: #555251;
  position: relative;
  transition: 0.3s;
  text-align: center;
  font-family: "Noto Serif JP", serif;
}
.l-header__gnav a:focus {
  color: #20519a;
}
.l-header__gnav a:focus::after {
  background-color: #ffffff;
}
@media (hover: hover) and (pointer: fine) {
  .l-header__gnav a:hover {
    color: #20519a;
  }
  .l-header__gnav a:hover::after {
    background-color: #ffffff;
  }
}
.l-header__gnavContact {
  width: 200px;
}
.l-header__gnavContact a {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-header__gnavContact a:focus {
  opacity: 0.7;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .l-header__gnavContact a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
.l-header__flexNav {
  z-index: 1000;
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  transition: top 0.8s, visibility 0.8s;
  visibility: hidden;
  background: rgba(255, 255, 255, 0.9);
  padding: 30px 0;
}
.l-header__flexNavList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10%;
}
.l-header__flexNavList li:not([class]) {
  width: calc((100% - 10% * 5) / 6);
}
.l-header__flexNavList a {
  display: block;
  color: #555251;
  position: relative;
  transition: 0.3s;
  text-align: center;
  font-family: "Noto Serif JP", serif;
}
.l-header__flexNavList a:focus {
  color: #20519a;
}
.l-header__flexNavList a:focus::after {
  background-color: #ffffff;
}
@media (hover: hover) and (pointer: fine) {
  .l-header__flexNavList a:hover {
    color: #20519a;
  }
  .l-header__flexNavList a:hover::after {
    background-color: #ffffff;
  }
}

#js-contents-wrapper {
  display: none;
}
@media screen and (max-width: 960px) {
  #js-contents-wrapper {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(0);
    transition: 0.3s;
    z-index: 9000;
  }
  #js-contents-wrapper.js-hide {
    transform: translateY(-100%);
    transition: 0.3s;
  }
}

/*==========================
下層ページ
==========================*/
.p-page .l-header,
.p-single .l-header {
  position: static;
  z-index: 1000;
}

/*==========================
l-footer
==========================*/
.l-footer {
  background-color: #253255;
}

.l-footer__inner {
  max-width: 1400px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 960px) {
  .l-footer__inner {
    padding: 20px 15px 0;
  }
}

.l-footer__logo {
  margin: 30px auto;
  display: none;
}
@media screen and (max-width: 960px) {
  .l-footer__logo {
    display: block;
  }
}
.l-footer__logo a {
  display: block;
  margin: auto;
  width: fit-content;
}

.l-footer__nav {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5vw;
  place-content: right;
  padding-top: 80px;
  padding-bottom: 100px;
}
@media screen and (max-width: 960px) {
  .l-footer__nav {
    display: none;
  }
}
.l-footer__nav ul li a {
  color: #ffffff;
  transition: 0.3s;
}
.l-footer__nav ul li a:focus {
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .l-footer__nav ul li a:hover {
    text-decoration: underline;
  }
}
.l-footer__nav ul li:first-child {
  margin-bottom: 20px;
}
.l-footer__nav ul li:first-child a {
  font-family: "Noto Serif JP", serif;
  font-size: 18px;
}
.l-footer__nav ul li:not(:first-child) a {
  color: #ffffff;
  font-size: 14px;
}
.l-footer__nav ul li + li {
  margin-top: 5px;
}

.l-footer__sns p {
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 10px;
}
.l-footer__sns ul {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 30px;
}
.l-footer__sns ul a {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
}
.l-footer__sns ul a:focus {
  opacity: 0.7;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .l-footer__sns ul a:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.l-footer__copryright {
  border-top: 0.5px solid #ffffff;
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.l-footer__copryright p {
  text-align: center;
  font-size: 12px;
  color: #ffffff;
}

/*============================
.l-container
============================*/
.l-container {
  max-width: 1400px;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}

/*============================
.l-section
============================*/
.l-section__inner {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 960px) {
  .l-section__inner {
    max-width: 100%;
  }
}

/*============================
.l-main
============================*/
.l-mv {
  position: relative;
  height: 100vh;
}
@media screen and (max-width: 960px) {
  .l-mv {
    height: auto;
  }
}
.l-mv-title {
  position: absolute;
  width: 1000px;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  z-index: 100;
}
@media screen and (max-width: 960px) {
  .l-mv-title {
    width: 80%;
  }
}

.l-mv-top {
  height: 100vh;
}
@media screen and (max-width: 960px) {
  .l-mv-top {
    height: auto;
  }
}
.l-mv-top__box {
  position: relative;
}
@media screen and (max-width: 960px) {
  .l-mv-top__box {
    height: 30vh;
  }
}
.l-mv-top__box picture img {
  object-fit: cover;
  object-position: bottom center;
  width: 100vw;
  height: 100vh;
}
@media screen and (max-width: 960px) {
  .l-mv-top__box picture img {
    height: 30vh;
  }
}
.l-mv-top__news {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(7px);
  z-index: 100;
  padding: 30px 50px;
}
@media screen and (max-width: 960px) {
  .l-mv-top__news {
    position: static;
    width: 85%;
    padding: 20px 10px;
    height: 100px;
    text-align: center;
    margin: 30px auto 20px;
    display: grid;
    place-content: center;
    place-items: center;
  }
}
.l-mv-top__newsTitle {
  font-weight: 600;
}
@media screen and (max-width: 960px) {
  .l-mv-top__newsTitle {
    font-size: 14px;
  }
}
.l-mv-top__newsText {
  font-size: 14px;
}
@media screen and (max-width: 960px) {
  .l-mv-top__newsText {
    font-size: 12px;
  }
}
.l-mv-top__newsText a {
  color: #20519a;
  transition: 0.3s;
}
.l-mv-top__newsText a:focus {
  opacity: 0.7;
}
@media (hover: hover) and (pointer: fine) {
  .l-mv-top__newsText a:hover {
    opacity: 0.7;
  }
}
.l-mv-top__off {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(7px);
  z-index: 100;
  padding: 30px 50px;
  width: 500px;
}
@media screen and (max-width: 960px) {
  .l-mv-top__off {
    position: static;
    width: 100%;
    padding: 20px 10px;
    text-align: center;
    margin: 0 auto 20px;
    display: grid;
    place-content: center;
    place-items: center;
  }
}
.l-mv-top__offTitle {
  font-weight: 600;
  margin-bottom: 1em;
  color: red;
}
@media screen and (max-width: 960px) {
  .l-mv-top__offTitle {
    font-size: 14px;
  }
}
.l-mv-top__offText {
  font-size: 14px;
}
@media screen and (max-width: 960px) {
  .l-mv-top__offText {
    font-size: 12px;
  }
}
.l-mv-top__offText a {
  color: #20519a;
  transition: 0.3s;
}
.l-mv-top__offText a:focus {
  opacity: 0.7;
}
@media (hover: hover) and (pointer: fine) {
  .l-mv-top__offText a:hover {
    opacity: 0.7;
  }
}

.l-mv-mission {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
@media screen and (max-width: 960px) {
  .l-mv-mission {
    top: 60px;
  }
}
.l-mv-mission picture img {
  object-fit: cover;
  width: 100vw;
  height: 60vh;
  object-position: top center;
}
@media screen and (max-width: 960px) {
  .l-mv-mission picture img {
    height: 200px;
  }
}

@media screen and (max-width: 960px) {
  .l-mv-page {
    margin-top: 50px;
  }
}
.l-mv-page__titEn {
  display: block;
  text-align: center;
  font-family: "Noto Serif JP", serif;
  font-weight: 200;
  font-size: clamp(40px, 10vw, 200px);
  letter-spacing: 0.3em;
  line-height: 1;
  color: #ffffff;
}

/*============================
.l-card
============================*/
.l-card__box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 50px;
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .l-card__box {
    gap: 50px 30px;
  }
}
.l-card__boxItem {
  z-index: 100;
  transition: 0.3s;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 4;
  gap: 0;
  position: relative;
}
@media screen and (max-width: 960px) {
  .l-card__boxItem {
    width: auto;
    height: 100%;
    flex: 0 0 75%;
    scroll-snap-align: center;
  }
}
.l-card__boxItem a {
  display: block;
}
.l-card__boxItem a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.l-card__boxItem:focus {
  opacity: 0.7;
}
@media (hover: hover) and (pointer: fine) {
  .l-card__boxItem:hover {
    opacity: 0.7;
  }
}
.l-card__boxImg {
  position: relative;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}
.l-card__boxImg::after {
  content: "";
  display: block;
  padding-top: 66.6%;
}
.l-card__boxImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit:cover;";
}
.l-card__boxContTit {
  font-weight: 500;
  font-size: 18px;
  width: fit-content;
  line-height: 1.5;
}
.l-card__boxContText {
  margin-bottom: 10px;
  font-size: 14px;
}
.l-card__boxContDate {
  font-size: 14px;
  color: #283135;
}
@media screen and (max-width: 960px) {
  .l-card__boxContDate {
    text-align: left;
  }
}

/*============================
m-button
============================*/
.m-btn__img {
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
  backface-visibility: hidden;
  display: block;
  width: fit-content;
  margin: auto;
}
.m-btn__img:focus {
  opacity: 0.7;
  transition: 0.3s;
}
@media (hover: hover) and (pointer: fine) {
  .m-btn__img:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}
@media screen and (max-width: 960px) {
  .m-btn__img {
    width: 200px;
    height: auto;
  }
}

/*============================
m-hide
============================*/
@media screen and (max-width: 960px) {
  .m-hide--sp {
    display: none;
  }
}
.m-hide--pc {
  display: none;
}
@media screen and (max-width: 960px) {
  .m-hide--pc {
    display: block;
  }
}

/*============================
m-only
============================*/
.m-only--sp {
  display: none;
}
@media screen and (max-width: 960px) {
  .m-only--sp {
    display: block;
  }
}
.m-only--pc {
  display: block;
}
@media screen and (max-width: 960px) {
  .m-only--pc {
    display: none;
  }
}

/*============================
m-tit
============================*/
.m-tit01 {
  position: relative;
  text-align: center;
  font-family: "Noto Serif JP", serif;
}
.m-tit01__en {
  color: #ffffff;
  font-weight: 200;
  font-size: clamp(50px, 10vw, 200px);
  letter-spacing: 0.3em;
  line-height: 1;
  display: block;
}
.m-tit01__jp {
  position: relative;
  z-index: 10;
  font-size: 40px;
  letter-spacing: 0.3em;
  display: block;
}
@media screen and (max-width: 960px) {
  .m-tit01__jp {
    font-size: 6vw;
  }
}

/*============================
m-pagenavi
============================*/
.m-pagenavi {
  margin-top: 60px;
}
@media screen and (max-width: 960px) {
  .m-pagenavi {
    margin-top: 40px;
  }
}
.m-pagenavi .wp-pagenavi {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.m-pagenavi .pages {
  display: none;
}
.m-pagenavi a {
  font-size: 14px;
  display: inline-block;
  line-height: 1;
  color: #20519a !important;
  margin: 0 6px;
  transition: 0.3s;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-items: center;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
  border-bottom: 1px solid #fff;
}
@media screen and (max-width: 960px) {
  .m-pagenavi a {
    font-size: 13px;
  }
}
.m-pagenavi a:hover {
  color: #20519a !important;
  border-bottom: 1px solid #20519a;
}
.m-pagenavi .current {
  color: #ffffff !important;
  background: #20519a;
  line-height: normal;
  font-size: 14px;
  width: 30px;
  height: 30px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: stretch;
  align-items: center;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
}
.m-pagenavi .current:hover {
  color: #fff !important;
}

/*============================
m-card
============================*/
.m-card__link {
  display: block;
}
.m-card__link:hover .m-card__tit {
  color: #20519a;
}
.m-card__img {
  position: relative;
  overflow: hidden;
}
.m-card__img::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.m-card__img img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  font-family: "object-fit:cover;";
}
.m-card__img img {
  transition: 0.3s;
}
.m-card__img:hover img {
  transition: 0.3s;
  transform: scale(1.1);
}
.m-card__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.m-card__data {
  font-size: 14px;
  color: #f8f8f8;
  font-family: "Cardo", serif;
  letter-spacing: 0em;
  letter-spacing: 0em;
}
.m-card__category {
  display: inline-block;
  background-color: #a59b42;
  font-size: 12px;
  color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  padding: 3px 30px;
}
@media screen and (max-width: 960px) {
  .m-card__category {
    display: none;
  }
}
.m-card__tit {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: 0.3s;
  margin-top: 5px;
}

/*==========================
共通タブ用 => .js-tab
==========================*/
.js-tab__switchItem {
  cursor: pointer;
}
.js-tab__main {
  position: relative;
}
.js-tab__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tab__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tab__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tab__mainItem.is-active.has-scroll {
  display: block;
}

/*===================
タブの中のタブ => .js-tab_inr
===================*/
.js-tabInr__switchItem {
  cursor: pointer;
}
.js-tabInr__main {
  position: relative;
}
.js-tabInr__mainItem {
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.js-tabInr__mainItem.has-scroll {
  display: none;
  position: static;
}
.js-tabInr__mainItem.is-active {
  opacity: 1;
  visibility: visible;
  position: static;
  z-index: auto;
}
.js-tabInr__mainItem.is-active.has-scroll {
  display: block;
}

/*==========================
共通トグル用 => .js-toggle
==========================*/
.js-toggle__switch {
  position: relative;
  cursor: pointer;
}
.js-toggle__switch::before, .js-toggle__switch::after {
  content: "";
  width: 13px;
  height: 2px;
  background: #000000;
  display: block;
  position: absolute;
  top: 50%;
  left: auto;
  right: 20px;
}
.js-toggle__switch::before {
  transform: translateY(-50%) rotate(0);
}
.js-toggle__switch::after {
  transition: 0.3s;
  transform: translateY(-50%) rotate(90deg);
}
.js-toggle__switch.is-active {
  transition: 0.3s;
}
.js-toggle__switch.is-active::after {
  transform: translateY(-50%) rotate(0deg);
}
.js-toggle__next {
  display: none;
}

/*==================
js-fadeIn
==================*/
.js-fadeIn {
  opacity: 0;
  transition: 1s;
}
.js-fadeIn.is-show {
  opacity: 1;
}
.js-fadeIn-up {
  transform: translate(0, 50px);
}
.js-fadeIn-up.is-show {
  transform: translate(0, 0);
  opacity: 1;
}

:root {
  --header-height: 60px;
}

.menu-button {
  appearance: none;
  background-color: #253255;
  border: none;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  height: var(--header-height);
  padding: 0;
  position: relative;
  transition: background-color 0.3s;
  width: var(--header-height);
  display: flex;
  margin-left: auto;
}
.menu-button.focus-visible {
  background-color: #676f79;
}
@media (hover) {
  .menu-button:hover {
    background-color: #676f79;
  }
}

.menu-button__icon {
  bottom: 0;
  height: 2px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
}
.menu-button__icon::before, .menu-button__icon::after {
  background-color: #fff;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.menu-button__icon[data-type=open] {
  background-color: #fff;
}
.menu-button__icon[data-type=open]::before {
  transform: translateY(-6px);
}
.menu-button__icon[data-type=open]::after {
  transform: translateY(6px);
}
.menu-button__icon[data-type=close]::before {
  transform: rotate(45deg);
}
.menu-button__icon[data-type=close]::after {
  transform: rotate(-45deg);
}

.drawer-menu {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9050;
}

.drawer-menu__overlay {
  animation-duration: var(--menu-toggle-duration);
  animation-fill-mode: forwards;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.drawer-menu:not([inert]) .drawer-menu__overlay {
  animation-name: menu-overlay-appeared;
}
.drawer-menu[inert] .drawer-menu__overlay {
  animation-name: menu-overlay-leaved;
}

@keyframes menu-overlay-appeared {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes menu-overlay-leaved {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.drawer-menu__container {
  animation-duration: var(--menu-toggle-duration);
  animation-fill-mode: forwards;
  background-color: #fff;
  border-left: #e2e2e2 1px solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 440px;
  min-width: 280px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 80%;
}
.drawer-menu[inert] .drawer-menu__container {
  animation-name: menu-container-leaved;
}
.drawer-menu:not([inert]) .drawer-menu__container {
  animation-name: menu-container-appeared;
}

@keyframes menu-container-appeared {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes menu-container-leaved {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
.drawer-menu__list {
  flex: 1;
  list-style: none;
  margin: 0;
  overflow-y: auto;
  padding: 0;
}

.drawer-menu__item {
  border-bottom: #e2e2e2 1px dashed;
}

.drawer-menu__link {
  text-align: left;
  align-items: center;
  color: inherit;
  display: block;
  justify-content: space-between;
  letter-spacing: 0.01em;
  padding: 1em 2.5em 1em 2em;
  position: relative;
  text-decoration: none;
  transition: background-color 0.3s;
}
.drawer-menu__link::after {
  border-right: 1px solid #aaa;
  border-top: 1px solid #aaa;
  bottom: 0;
  content: "";
  display: inline-block;
  height: max(8px, 0.5em);
  margin: auto 0;
  position: absolute;
  right: 24px;
  top: 0;
  transform: rotate(45deg);
  width: max(8px, 0.5em);
}
.drawer-menu__link:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.drawer-menu__link[aria-current] {
  background-color: rgba(0, 0, 0, 0.1);
}
.drawer-menu__link[aria-current]::after {
  content: none;
}
@media (hover) {
  .drawer-menu__link:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.drawer-menu__en-label {
  display: block;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-style: italic;
  text-transform: uppercase;
  font-size: max(18px, 0.75em);
}

.drawer-menu__jp-label {
  color: #aaa;
  display: block;
  font-size: max(10px, 0.75em);
  letter-spacing: 0.1em;
}

.drawer-menu__close-button {
  border-bottom: #e2e2e2 1px solid;
  order: -1;
  text-align: right;
}

.global-header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.global-header__container {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: 0;
}

.logo {
  padding-left: 15px;
}
.logo a {
  display: block;
  width: 150px;
}
.logo a img {
  display: block;
}

[inert] {
  cursor: default;
  pointer-events: none;
}

[inert],
[inert] * {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.u-visually-hidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/*==========================
トップ
.p-top
==========================*/
.p-top-mission {
  font-family: "Noto Serif JP", serif;
  margin: 200px auto;
}
@media screen and (max-width: 960px) {
  .p-top-mission {
    margin: 70px auto 100px;
  }
}
.p-top-mission .m-tit01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .p-top-mission .m-tit01 {
    margin-bottom: 30px;
  }
}
.p-top-mission .m-tit01__en {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: -20%;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .p-top-mission .m-tit01__en {
    bottom: 40%;
  }
}
.p-top-mission__text {
  margin-bottom: 100px;
}
@media screen and (max-width: 960px) {
  .p-top-mission__text {
    margin-bottom: 50px;
  }
}
.p-top-mission__text p {
  margin-bottom: 2em;
  text-align: center;
  line-height: 2;
  font-size: 20px;
}
@media screen and (max-width: 960px) {
  .p-top-mission__text p {
    font-size: 16px;
    margin-bottom: 1em;
  }
}

.p-top-topics {
  margin: 200px auto;
}
@media screen and (max-width: 960px) {
  .p-top-topics {
    margin: 100px auto;
  }
}
.p-top-topics .m-tit01 {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .p-top-topics .m-tit01 {
    margin-bottom: 30px;
  }
}

.p-top-brands {
  margin: 200px auto;
}
@media screen and (max-width: 960px) {
  .p-top-brands {
    margin: 100px auto;
  }
}
.p-top-brands .m-tit01 {
  margin-bottom: 30px;
}
@media screen and (max-width: 960px) {
  .p-top-brands .m-tit01 {
    margin-bottom: 10px;
  }
}
.p-top-brands .m-tit01__en {
  margin-bottom: 30px;
}
@media screen and (max-width: 960px) {
  .p-top-brands .m-tit01__en {
    margin-bottom: 10px;
  }
}
.p-top-brands__text {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .p-top-brands__text {
    margin-bottom: 30px;
  }
}
.p-top-brands__text p {
  margin-bottom: 2em;
  text-align: center;
  line-height: 2;
  font-family: "Noto Serif JP", serif;
  font-size: 20px;
}
@media screen and (max-width: 960px) {
  .p-top-brands__text p {
    font-size: 16px;
    margin-bottom: 1em;
  }
}
.p-top-brands__img {
  margin-bottom: 60px;
}
@media screen and (max-width: 960px) {
  .p-top-brands__img {
    margin-bottom: 30px;
  }
}

.p-top-access {
  margin: 200px auto 100px;
}
@media screen and (max-width: 960px) {
  .p-top-access {
    margin: 100px auto 50px;
  }
}
.p-top-access .m-tit01 {
  margin-bottom: 50px;
}
@media screen and (max-width: 960px) {
  .p-top-access .m-tit01 {
    margin-bottom: 30px;
  }
}
.p-top-access__flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}
@media screen and (max-width: 960px) {
  .p-top-access__flex {
    flex-wrap: wrap;
    gap: 30px;
  }
}
.p-top-access__flexMap {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .p-top-access__flexMap {
    width: 100%;
  }
}
.p-top-access__flexMap iframe {
  width: 100%;
}
@media screen and (max-width: 960px) {
  .p-top-access__flexMap iframe {
    height: 300px;
  }
}
.p-top-access__flexText {
  width: 50%;
}
@media screen and (max-width: 960px) {
  .p-top-access__flexText {
    width: 100%;
  }
}
.p-top-access__flexText p + p {
  margin-top: 1.5em;
}
.p-top-access__flexTextMap {
  color: #3b40cb;
  transition: 0.3s;
}
.p-top-access__flexTextMap:focus {
  color: #2c30a7;
  text-decoration: underline;
}
@media (hover: hover) and (pointer: fine) {
  .p-top-access__flexTextMap:hover {
    color: #2c30a7;
    text-decoration: underline;
  }
}
